import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    permissions: [],
  }),
  actions: {
    async fetch() {
      const { data: admin } = useAuth();

      admin.value.roles.forEach((_role) => {
        this.permissions = this.permissions.concat(_role.permissions);
      });
    },
    hasPermission(permissions) {
      return this.permissions.some(_permission =>
        permissions.includes(_permission.name) || _permission.name === "Super Admin",
      );
    },
  },
});
